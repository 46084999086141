import React from 'react';
import CommunityLogo from './assets/logo.svg';
import './Login.css';
import { TextInput } from './components/TextInput';
import { Api, UserInfo } from './api/api';

interface LoginProps {
    user: string,
    userinfo?: UserInfo,
    api: Api,
    onChange: (e: any) => void
}

interface LoginState {
    user: string,
    password: string,
}

export class Login extends React.Component<LoginProps, LoginState> {

    constructor(props: LoginProps) {
        super(props);
        this.setState({
            user: props.user,
            password: ''
        })
    }

    public render() {
        let user = this.state === null ? '' : this.state.user;
        let password = this.state === null ? '' : this.state.password;
        return (
            <div className='login-container'>
                <div className='login-logo'>
                    <img src={CommunityLogo} className="login-logo" alt="Comunidad.es" />
                </div>
                <div className='login-input'>
                    <TextInput label='User' value={user} onChange={this.onUserTyped}></TextInput>
                    <TextInput label='Password' type='password' value={password} onChange={this.onPasswordTyped}></TextInput>
                    <button onClick={this._}>Login</button>
                </div>
            </div>
        );
    }

    private onUserTyped = (e: any) => {
        this.setState({
            user: e.currentTarget.value
        })
    }

    private onPasswordTyped = (e: any) => {
        this.setState({
            password: e.currentTarget.value
        })
    }

    private _() {

    }
}