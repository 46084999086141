import * as React from 'react';

interface TextInputProps {
  label: string,
  type?: string,
  value?: string,
  onChange: (e: any) => void
}

export const TextInput = (props: TextInputProps) => (
  <div className="input-group">
    <label>{props.label}</label>
    <div className="input-focus-group">
        <input type={props.type === null ? "text" : props.type} placeholder={props.label} value={props.value || ''} onChange={props.onChange} />
        <div className="line" />
    </div>
  </div>
);

