import React from 'react';
import { Header } from './Header';
import { Nav } from './Nav';
import { Footer } from './Footer';
import { Login } from'./Login';
import './App.css';
import { HashRouter as Router } from 'react-router-dom';
import { Api, GetApi } from './api/api';


interface AppState {
  api?: Api
}


export class App extends React.Component<{}, AppState> {
  constructor(props: {}) {
    super(props);
    this.state = {};
    this.getApiAsync();
  }

  public render() {
    if (this.state.api === null)
      return null;

    let api = this.state.api;
   // if (false)
      return (<Login user={this.getUser()} api={api!} onChange={ () => {}}></Login>)

    // return !this.state.api ? null : (
    //   <Router>
    //     <div className="app-div">
    //       <Header/>
    //       <div className="below-header">
    //         <Nav />
    //         <div className="right-of-nav">
    //           {/* <Main api={this.state.api}/> */}
    //           <div className="flex-separator" />
    //           <Footer />
    //         </div>
    //       </div>
    //     </div>
    //   </Router>
    // );
  }

  private async getApiAsync() {
    const api = await GetApi();
    this.setState({ api });
  }

  private getUser(): string {
    return ''; // TODO implement this
  }
}
export default App;
