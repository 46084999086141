
export interface UserInfo {
    firstName?: string
    lastName?: string
    idToken?: string
    avatar?: string
}

export interface Api {
    getUserInfo(): UserInfo

}

let api: Api | undefined;

export async function GetApi(): Promise<Api> {
    if (!api) {
        api = await createApi();
    }
    return api;
}

function backendBaseUrl(): string {
    if (process.env.NODE_ENV !== 'production') {
        return "http://127.0.0.1:8000";
    }
    return "https://api.comunidad.iconiqlabs.com";
}

async function createApi(): Promise<Api> {

    const response = await fetch(backendBaseUrl() + '/api/userinfo'); // TODO handle error
    const userInfo = await response.json() as UserInfo;
    
    return new ApiImpl(userInfo as UserInfo);
}

export class ApiImpl implements Api {

    private userInfo: UserInfo;

    private tokenExpiry: number


    constructor(userInfo: UserInfo) {
        console.log("user info: ", userInfo);

        if (!userInfo.idToken) {
            throw new Error("Missing access token");
        }

        this.userInfo = userInfo;

        // const claims = this.decodeIdToken(userInfo.idToken);
        this.tokenExpiry = this.decodeIdToken(userInfo.idToken);

    }

    public getUserInfo(): UserInfo {
        return this.userInfo;
    }


    private decodeIdToken(idToken: string) {
        const [, payload,] = idToken.split('.');

        const claims = JSON.parse(atob(payload));
        console.log("claims: ", claims);

        const tokenExpiry = claims.exp;
        if (tokenExpiry) {
            return tokenExpiry;
        } else {
            throw Error("token does not have expiry time");
        }
    }
}


